import * as React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../components/layout";
import Seo from "../components/seo";
import "./post.css";

function BlogPost({ data }) {
  return (
    <Layout pageTitle={data.mdxBlogPost.title}>
      <Seo
        title={data.mdxBlogPost.title}
        description={data.mdxBlogPost.description}
      />
      <div className="post__meta">🌿 {data.mdxBlogPost.date}</div>
      <article className="post" itemScope itemType="http://schema.org/Article">
        <MDXRenderer>{data.mdxBlogPost.body}</MDXRenderer>
      </article>
    </Layout>
  );
}

export const query = graphql`
  query ($id: String) {
    mdxBlogPost(id: { eq: $id }) {
      title
      description
      body
      date(formatString: "MMM YYYY")
    }
  }
`;

export default BlogPost;
